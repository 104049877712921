.navbar-header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin: 0;
    list-style: none;
    background: transparent;

    a {
        position: relative;
        display: block;
        padding: 5px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        transition: .1s ease-in-out;

        &:hover {
            color: #0095ff;
            text-decoration: none;
        }

        &.active {
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #0095ff;
            }
        }
    }

    &.navbar-mobile {
        flex-direction: column;

        li {
            width: 100%;

            a {
                text-align: center;
            }
        }
    }
}

@media (max-width: 992px) {
    .navbar-header {
        display: none;

        &.navbar-mobile {
            display: flex;
        }
    }
}