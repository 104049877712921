.item-details {
    padding: 0 20px;

    .item-wrapper {
        padding-top: 20px;
    }
}

.item-header {
    font-size: 30px;
    font-weight: 600;
    line-height: 37px;
}

.item-description-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.item-description {
    width: 49%;
    margin-right: 2%;
    margin-bottom: 2%;
    padding: 20px 15px;
    background: #ebf0f8;
    overflow: hidden;
    text-overflow: ellipsis;

    &:nth-child(2n) {
        margin-right: 0;
    }

    &__title {
        margin-bottom: 20px;
        font-size: 23px;
        line-height: 28px;
        font-weight: 600;
    }

    &__body {
        font-size: 14px;
        color: #313131;

        &--link {
            position: relative;
            padding-bottom: 3px;
            color: #0095ff;
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: rgba(#0095ff, .5);
            }

            &:hover {
                text-decoration: none;
            }
        }

        &--list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .superclass-decor {
                margin-bottom: 15px;
                padding: 0px 10px;
                font-size: 14px;
                background: #c4cbd8;
                border-radius: 3px;
                color: #313131;

                a {
                    color: inherit;
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }

            .superclass-disabled {
                opacity: .5;
            }
        }
    }
}

.item-usage-wrapper {
    h4 {
        font-size: 23px;
        font-weight: 600;
        line-height: 28px;

        & + p {
            margin-bottom: 25px;
            font-size: 18px;
            line-height: 22px;
        }
    }
}

.item-usage-table {
    width: 100%;

    tr th {
        padding: 15px;
        font-size: 16px;
        font-weight: 500;
        font-family: inherit;
        color: #919DB6;
    }

    tr td {
        padding: 25px 20px;
        font-size: 14px;
        &:nth-child(1) {
            width: 20%;;
        }
        &:nth-child(2) {
            width: 20%;
        }
        &:nth-child(3) {
            width: 45%;
        }
        &:nth-child(4) {
            width: 15%;
        }
    }

    .class-name-decor {
        padding: 3px;
        background: #f1f2f3;
        border-radius: 3px;
        color: #0095ff;
    }

    tbody tr {
        border-bottom: 1px solid #f1f2f3;
    }

    .head {
        background: #F1F2F3;

        td {
            font-size: 18px;
        }

        .class-decor {
            margin-left: 20px;
            padding: 3px;
            font-size: 14px;
            background: #37c974;
            border-radius: 3px;
            color: #fff;
            text-align: center;
        }
    }
}

.class-definition-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #d7dbe3;

    h3 {
        font-size: 23px;
        font-weight: 600;
        line-height: 28px;
    }

    .chevron-down {
        cursor: pointer;
        transition: .2s ease-in-out;

        i {
            font-size: 25px;
        }
    }
}

.class-definition-body {
    margin-bottom: 20px;
    background: linear-gradient(0deg, #EBF0F8, #EBF0F8), #1D2548;
    border-radius: 3px;

    pre {
        padding: 20px;
        font-size: 14px;
        font-weight: 300;
        font-family: 'Roboto';
        color: #0c4d7b;
    }
}

.class-definition-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 23px;
    background: #15486D;

    .left {
        font-size: 14px;
        color: #fff;
    }

    .download-btn {
        padding: 4px 9px;
        background: #1f5b86;
        color: #fff;
        font-size: 14px;
        text-align: center;
    }
}

.markdown {
    padding: 20px;
    border: 2px solid #EBF0F8;

    pre {
        padding: 10px;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 300;
        background: #EBF0F8;

        code {
            color: darkblue
        }
    }
}

.context .item-description,
.class-definition-details .item-description {
    display: inline-block;
    width: auto;
    margin-right: 0;
    margin-bottom: 5px;
}

@media (max-width: 992px) {
    .item-details {
        padding: 0;
    }
}