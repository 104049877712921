.platform-header {
    padding: 15px 20px;
    background: #082c46;

    .hamburger {
        display: none;
        font: inherit;
        overflow: visible;
        margin: 0;
        padding: 15px;
        cursor: pointer;
        transition-timing-function: linear;
        transition-duration: .15s;
        transition-property: opacity,filter;
        text-transform: none;
        color: inherit;
        border: 0;
        background-color: transparent;
        &-box {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;
        }
        &-inner {
            display: block;
            &::before, &::after {
                display: block;
                content: '';
            }
            &::before {
                top: -10px
            }
            &::after {
                bottom: -10px;
            }
        }
    }

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: #fff;
    }

    .hamburger-slider .hamburger-inner{
        top:2px;
    }
    
    .hamburger-slider .hamburger-inner:before{
        top:10px;
        transition-timing-function:ease;
        transition-duration:.15s;
        transition-property:transform,opacity;
    }
    
    .hamburger-slider .hamburger-inner:after{
        top:20px;
    }
    
    .hamburger-slider.is-active .hamburger-inner{
        transform:translate3d(0,10px,0) rotate(45deg);
    }
    
    .hamburger-slider.is-active .hamburger-inner:before{
        transform:rotate(-45deg) translate3d(-5.71429px,-6px,0);
        opacity:0
    }
    
    .hamburger-slider.is-active .hamburger-inner:after{
        transform:translate3d(0,-20px,0) rotate(-90deg);
    }
}

@media (max-width: 992px) {
    .platform-header .hamburger {
        display: inline-block;
    }
}