.logo-block {
    display: flex;
    align-items: center;
}

.logo-text {
    padding: 10px;
    color: #fff;

    &-big {
        font-size: 21px;
    }

    &-small {
        font-size: 16px;
    }

    span {
        display: block;
        font-weight: bold;
        color: lightgrey;
        text-transform: uppercase;
    }
}