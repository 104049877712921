.main-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px 20px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    background: #082C46;
    color: #fff;

    a {
        padding: 4px;
        font-family: inherit;
        border-bottom: 0.5px solid rgba(255, 255, 255, .7);
        color: #fff;
        transition: .1s ease-in-out;

        &:hover {
            color: #0095ff;
            text-decoration: none;
        }
    }
}