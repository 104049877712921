.page-navigation {
    padding: 8px 20px;
    font-size: 12px;
    background: #15486D;

    ol li:nth-last-child(2) {
        svg {
            fill: #fff;
        }
    }

    a {
        color: #919db6;
        transition: .2s ease-in-out;

        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }

    p {
        color: #fff
    }

    svg {
        fill: #919db6;
    }
}